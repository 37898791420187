exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-card-index-tsx": () => import("./../../../src/pages/card/index.tsx" /* webpackChunkName: "component---src-pages-card-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-resume-index-tsx": () => import("./../../../src/pages/resume/index.tsx" /* webpackChunkName: "component---src-pages-resume-index-tsx" */),
  "component---src-templates-project-index-tsx": () => import("./../../../src/templates/project/index.tsx" /* webpackChunkName: "component---src-templates-project-index-tsx" */)
}

